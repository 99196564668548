
.backgroundImage {
  background-position: center;
  background-size: cover;
  position: relative;
  height: 400px;

  .gradientCover {
    background: linear-gradient(0deg, rgba(17,17,17,1) 0%, rgba(17,17,17,0) 50%);
    height: 100%;
    width: 100%;
  }
}

.content {
  position: absolute;
  text-align: center;
  top: 340px;
  width: 100%;

  .title {
    font-size: 26px;
    margin-bottom: 12px;
  }
  .description {
    font-size: 14px;
    padding: 0 20px;
  }
  .buttonContainer {
    margin-top: 12px;
    position: relative;
    vertical-align: top;

    .greenDot {
      animation: blink-animation 3s infinite;
      background-color: #00ff00;
      border-radius: 10px;
      height: 10px;
      left: -18px;
      position: absolute;
      top: 12px;
      width: 10px;
      z-index: 1000;
    }
    button {
      color: black;
    }
  }

  .loadingSpinner {
    margin: 16px;
  }

  .notes {
    font-size: 14px;
    margin-top: 32px;
    opacity: .6;
    p {
      margin: 0 0;
    }
  }

  .errorMessage {
    color: red;
    font-size: 12px;
    margin: 8px;
  }
}

@keyframes blink-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .3;
  }
  100% {
    opacity: 1;
  }
}

.loginNavigation {
  position: absolute;
  text-align: center;
  top: 300px;
  width: 100%;

  .icon {
    margin-bottom: 12px;

    svg {
      height: 80px;
      width: 80px;
    }
  }

  .message {
    font-size: 20px;
    margin-bottom: 12px;
    padding: 0 24px;
  }

  .buttonContainer {
    .row {
      margin-bottom: 12px;
    }
    button {
      color: black;
    }
  }
}